// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--rcns1";
export var box__lEtPl = "PlasmicPricing-module--box__lEtPl--3jBdv";
export var header = "PlasmicPricing-module--header--arUMc";
export var section__thBzq = "PlasmicPricing-module--section__thBzq--3QfnY";
export var box__mg8V3 = "PlasmicPricing-module--box__mg8V3--qGwrD";
export var plan__k37CP = "PlasmicPricing-module--plan__k37CP--10d9F";
export var box__ovD04 = "PlasmicPricing-module--box__ovD04--3nMm5";
export var box___1JSfw = "PlasmicPricing-module--box___1JSfw--q8-0N";
export var bullet__uDd62 = "PlasmicPricing-module--bullet__uDd62--1x0iJ";
export var bullet__vKLry = "PlasmicPricing-module--bullet__vKLry--rjmNZ";
export var bullet__gzD7S = "PlasmicPricing-module--bullet__gzD7S--1ZQ07";
export var plan__hsGe = "PlasmicPricing-module--plan__hsGe--15fbC";
export var box__bGy0G = "PlasmicPricing-module--box__bGy0G--1dmYh";
export var box__rmob1 = "PlasmicPricing-module--box__rmob1--3QuOR";
export var bullet__bLBgV = "PlasmicPricing-module--bullet__bLBgV--2X2yK";
export var bullet__kC3Wy = "PlasmicPricing-module--bullet__kC3Wy--3OLtQ";
export var bullet__p8UsS = "PlasmicPricing-module--bullet__p8UsS--24cjJ";
export var bullet__cscSj = "PlasmicPricing-module--bullet__cscSj--zAoQx";
export var plan__uBazl = "PlasmicPricing-module--plan__uBazl--3cU8J";
export var box__kmytQ = "PlasmicPricing-module--box__kmytQ--3fn0o";
export var box___5LBbx = "PlasmicPricing-module--box___5LBbx--3Mvwm";
export var bullet__gcz47 = "PlasmicPricing-module--bullet__gcz47--Fy4kW";
export var bullet__xy1ZA = "PlasmicPricing-module--bullet__xy1ZA--2qW4u";
export var bullet__uKa2B = "PlasmicPricing-module--bullet__uKa2B--1CCiE";
export var section___75JPl = "PlasmicPricing-module--section___75JPl--2ZKcO";
export var faq__mzbhf = "PlasmicPricing-module--faq__mzbhf--41i_N";
export var faq__yId7T = "PlasmicPricing-module--faq__yId7T--2R0wO";
export var faq__eqGS = "PlasmicPricing-module--faq__eqGS--1uN33";
export var footer = "PlasmicPricing-module--footer--k54-O";