// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--27EcQ";
export var box___8BZu9 = "PlasmicPlan-module--box___8BZu9--2wZoE";
export var box__cuZ1O = "PlasmicPlan-module--box__cuZ1O--2lLcm";
export var slotName = "PlasmicPlan-module--slotName--1clZ5";
export var box__ddHof = "PlasmicPlan-module--box__ddHof--2JMe3";
export var box__kVtZl = "PlasmicPlan-module--box__kVtZl---UwfZ";
export var bullet__xTncw = "PlasmicPlan-module--bullet__xTncw--34X78";
export var bullet__yrNy0 = "PlasmicPlan-module--bullet__yrNy0--24Kj_";
export var bullet__dN3GD = "PlasmicPlan-module--bullet__dN3GD--MBUgR";
export var box__ytja5 = "PlasmicPlan-module--box__ytja5--2j_Qv";
export var svg___0RIux = "PlasmicPlan-module--svg___0RIux--1wQXv";